//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable @typescript-eslint/no-var-requires */
import { getUserInfo, getUserOneTicketInfo } from '@/api/user';
import { param2Obj } from '@/utils/help';
import { getToken } from '@/utils/auth';
import { ERROR_OK } from '@/api/config';
import Vue from 'vue';
import {
  CountDown, Cell, CellGroup, Tag, Icon, Dialog, Grid, GridItem, Badge, Empty,
} from 'vant';
import { getPortSkuList } from '@/api/home';
import Buy from '../../components/Buy/index.vue';

Vue.use(CountDown);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tag);
Vue.use(Icon);
Vue.use(Dialog);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Badge);
Vue.use(Empty);

const IMG = require('@/assets/icon/emptyUser.png');

export default Vue.extend({
  name: 'UserService',
  components: { Buy },
  data() {
    return {
      isRedeemLink: false,
      ticketList: [],
      imgSrc: IMG,
      showpassWord: false,
      current: '',
      vusalName: ['alex', 'manolo', 'galax', 'jone', 'woma'],
      showBuy: false,
      currentSku: {},
      buyParams: {},
      buyText: '',
    };
  },
  created() {
    const { redeemCode } = param2Obj(window.location.search);
    if (!redeemCode) {
      this.isRedeemLink = false;
    } else {
      this.isRedeemLink = redeemCode;
    }
    this.getData();
  },
  methods: {
    handleShowPwd(item) {
      this.showpassWord = true;
      this.current = item;
    },
    getData() {
      if (this.isRedeemLink) {
        getUserOneTicketInfo({ redeemCode: this.isRedeemLink })
          .then((res) => {
            this.ticketList = res.data || [];
          });
      } else {
        if (!getToken()) {
          this.$router.push({ name: 'Login' });
          return;
        }
        getUserInfo().then((res) => {
          if (res.code === ERROR_OK) {
            this.ticketList = res.data || [];
          }
        });
      }
    },
    // handleRestPassword() {
    //   Dialog.alert({
    //     title: '温馨提示',
    //     message: '自动重置密码功能每个用户每天只能使用一次，请确认您是否提前输入了错误的密码.',
    //     showCancelButton: true,
    //   }).then(() => {
    //     // on close
    //   });
    // },
    handleConfirm() {
      this.showpassWord = false;
      const title = this.current.ticketType === 0 ? `Please use No. ${this.current.seatNo} profile`
        : 'Tips';
      Dialog.alert({
        title,
        message: `password: ${this.current.carPassword}`,
        confirmButtonText: 'Confirm',
      }).then(() => {
        // on close
      });
    },
    handleShowBuy(car) {
      const { categoryId, ticketType, ticketNo } = car;
      getPortSkuList({
        categoryId,
        selectableProductType: ticketType,
      }).then((res) => {
        if (res.code === ERROR_OK) {
          this.buyText = res.data.sku_page_btn;
          this.currentSku = this.formatterGoodSku(res.data);
          this.buyParams = { isRenewalBill: 1, ticketNo };
          this.showBuy = true;
        }
      });
    },
    formatterGoodSku(data) {
      const {
        monthNumSet, sku_page_p1: kName, /* screensSet, */ skuList,
      } = data;
      const tree = [
        {
          k: kName,
          k_id: '1',
          k_s: 'monthNum',
          v: monthNumSet.map((item, index) => ({
            id: item,
            name: skuList[index].skuMonthName,
            imgUrl: skuList[index].skuImg,
          })),
        },
      ];
      const list = skuList.map((item) => {
        const {
          screens, monthNum, originalPrice, id, actualPrice, discount,
        } = item;
        return {
          id,
          screens,
          monthNum,
          price: actualPrice,
          stock_num: 110,
          discount,
          originalPrice,
        };
      });
      return { tree, list };
    },
  },
});
